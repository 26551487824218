<template>
  <div>
  <b-table stripe :items="this.$attrs.lines" :fields="fields">
    <template #cell(actions)="row">
      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template v-slot:button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="text-body align-middle mr-25"
          />
        </template>
        <b-dropdown-item  v-on:click="deposit(row.item.partnerUserId)">
          <feather-icon
            icon="DollarSignIcon"
            class="mr-50 text-success"
          />
          <span class="text-success">Liberar DEPOSITO</span>
        </b-dropdown-item>
        <b-dropdown-item  v-on:click="sendCashback(row.item.id)">
          <feather-icon
            icon="DollarSignIcon"
            class="mr-50"
          />
          <span>Liberar Cashback</span>
        </b-dropdown-item>
        <b-dropdown-item  v-on:click="kickUser(row.item.id)">
          <feather-icon
            icon="CloudOffIcon"
            class="mr-50"
          />
          <span>Encerrar Sessão</span>
        </b-dropdown-item>
        <b-dropdown-item  v-on:click="debit(row.item.id)">
          <feather-icon
            icon="DollarSignIcon"
            class="mr-50 text-warning"
          />
          <span class="text-warning">Gerar DEBITO</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
    <b-modal id="deposit-modal" ref="deposit-modal"hide-footer>
      <template #modal-title>
        Autorizar depósito
      </template>
      <div class="d-block text-center">
        <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label="Digite o valor a ser creditado"
              label-for="value"
            >
              <b-form-input
                id="value"
                v-model="depositValue"
                name="value"
                placeholder="Digite o valor a ser creditado"
                type="number"
              />
            </b-form-group>
            <b-button class="mt-3" block @click="sendDepositData()">Enviar Deposito</b-button>
          </b-form>
        </div>
    </b-modal>
    <b-modal id="debit-modal" ref="debit-modal"hide-footer>
      <template #modal-title>
        Autorizar debito em conta
      </template>
      <div class="d-block text-center">
        <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label="Digite o valor a ser DEBITADO"
              label-for="value"
            >
              <b-form-input
                id="value"
                v-model="depositValue"
                name="value"
                placeholder="Digite o valor a ser DEBITADO"
                type="number"
              />
            </b-form-group>
            <b-button class="mt-3" block @click="sendDebitData()">Enviar Débito</b-button>
          </b-form>
        </div>
    </b-modal>
  </div>
</template>

<script>
import {BTable, BDropdown, BDropdownItem, BModal, BFormGroup,BForm,BButton,BFormInput} from 'bootstrap-vue'

export default{
  name: 'OneLineDataResult',
  props: {},
  data () {
    return {
      fields: ['id', 'name', 'partnerId','partnerUserId', 'internal', 'chat_blocked', 'actions'],
      depositValue:'',
      alterUserId:0,
    }
  },
  components: {
    BTable, BDropdown, BDropdownItem,BModal,BFormGroup,BForm,BButton,BFormInput
  },
  methods:{
    sendCashback(id){
      console.log(id)
    },
    deposit(id){
      this.alterUserId = id;
      this.$refs['deposit-modal'].show()
      console.log(id)
    },
    debit(id){
      this.alterUserId = id;
      this.$refs['debit-modal'].show()
      console.log(id)
    },
    kickUser(id){
      this.$http.post('/player/kick',{
          playerId: id,
        })
        .then(res => {
            console.log(res.data)
        })
   },
   sendDepositData(){
     this.$httpPlatform.post('/platform-operations/makeDeposit',{
        userId: this.alterUserId,
        amount: this.depositValue,
      })
      .then(res => {
          console.log(res.data)
      })
   },
   sendDebitData(){
     this.$httpPlatform.post('https://api.bingolar.tv/api/platform-operations/makeDebit',{
        userId: this.alterUserId,
        amount: this.depositValue,
      })
      .then(res => {
          this.$refs['debit-modal'].hide()
          alert(res.data)
      })
    }
  },
  created () {
  }
}
</script>
